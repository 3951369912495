/* eslint-disable react/no-array-index-key */
import { concat } from 'lodash'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

interface IFileUploadProps {
    files: any
    setFiles: (files: any) => void
    accept?: any
    max?: number
    error?: string
}

const FileUpload = (props: IFileUploadProps) => {
    const { files, setFiles, accept, max = 1, error } = props
    const [rejectedFiles, setRejectedFiles] = useState<any>(null)
    const { t } = useTranslation()

    const { getRootProps, getInputProps } = useDropzone({
        accept: accept || 'image/*',
        onDrop: (acceptedFiles: any, fileRejections: any) => {
            setRejectedFiles(fileRejections)

            if (files.length < max) {
                setFiles(
                    concat(
                        files,
                        acceptedFiles.map((file: any) => {
                            return file
                        }),
                    ),
                )
            } else {
                setFiles(
                    acceptedFiles.map((file: any) => {
                        return file
                    }),
                )
            }
        },
        maxFiles: max,
    })

    return (
        <div
            {...getRootProps({
                className: `w-full dropzone border-primary rounded border-2 border-dashed flex justify-center align-middle items-center files-center  p-2 cursor-pointer ${
                    error ? 'border-red-300' : 'border-base-300'
                } ${max > 1 ? 'h-24' : 'h-12'}`,
            })}
        >
            <div>
                {files && files.length > 0 ? (
                    <>
                        {files.map((file: any, index: number) => (
                            <div key={file.name + index} className="flex gap-2 items-center w-full">
                                <p className="break-words">{file.name}</p>
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        <input {...getInputProps()} />
                        <div>
                            <p className="text-info text-center">
                                {t('api.drag_and_drop_or_click_to_upload_file')}
                            </p>
                            {rejectedFiles && rejectedFiles.length > 0 && (
                                <div>
                                    <p className="text-error">
                                        {t('message.exceeds_upload_limit')} {max}
                                    </p>
                                </div>
                            )}
                        </div>
                        {error && <p className="text-red-300 text-sm">{error}</p>}
                    </>
                )}
            </div>
        </div>
    )
}

export default FileUpload
