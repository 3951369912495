import classNames from 'classnames'
import Button from 'components/button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { orderBy } from 'lodash'

import { Link } from 'react-router-dom'
import ExcelTable from '../components/ExcelTable'
import FileUpload from '../../../components/FileUpload'
import { parseExcelData } from '../query'
import useTranslationData from '../../translation/hooks/useTranslationData'

const cardClass = 'bg-white px-4 py-4 rounded-xl h-fit'

const NovoImport = () => {
    const {
        translation: { t },
    } = useTranslationData()
    const parseExcelDataFunc = useMutation(['higeherDiscountrequest'], parseExcelData)

    const novoImportFormik = useFormik<any>({
        initialValues: {
            files: [],
        },
        validationSchema: Yup.object().shape({
            files: Yup.array().length(1, 'Please upload only one file'),
        }),
        onSubmit: async (values, { resetForm }) => {
            await parseExcelDataFunc.mutate(values.files[0])
            resetForm()
        },
    })

    return (
        <div className="flex flex-col gap-8">
            <div className={classNames('flex flex-col gap-8', cardClass)}>
                <div className="text-secondary border-primary font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {t('api.upload_configurations')}
                </div>
                <div>
                    <div className="flex flex-col md:flex-row items-center gap-8">
                        <div className="flex-1">
                            <FileUpload
                                files={novoImportFormik.values.files}
                                setFiles={files => novoImportFormik.setFieldValue('files', files)}
                                max={1}
                                error={
                                    novoImportFormik.errors.files && novoImportFormik.touched.files
                                        ? String(novoImportFormik.errors.files)
                                        : ''
                                }
                            />
                        </div>
                        <div className="w-fit flex items-center gap-4">
                            <Button
                                id="file_submit"
                                label={t('api.extract_data')}
                                variant="outline"
                                type="button"
                                isLoading={parseExcelDataFunc.isLoading}
                                onClick={() => novoImportFormik.handleSubmit()}
                            />
                            <Link to="/novo-configuration">
                                <Button
                                    id="back"
                                    label={t('api.back')}
                                    type="button"
                                    disabled={parseExcelDataFunc.isLoading}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {parseExcelDataFunc?.data?.data ? (
                <div className={classNames(cardClass)}>
                    <ExcelTable
                        excelData={
                            orderBy(
                                parseExcelDataFunc?.data?.data.data,
                                ['updated_at'],
                                ['desc'],
                            ) || []
                        }
                        isLoading={parseExcelDataFunc.isLoading}
                    />
                </div>
            ) : (
                <p className="text-center">{t('api.import_text')}</p>
            )}
        </div>
    )
}

export default NovoImport
