import AlertModal from 'components/modal/alertModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillDelete, AiOutlineEye, AiOutlineHistory } from 'react-icons/ai'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { NOVO_CONFIGURATION_ROUTE } from 'route/appRoutes'
import { deleteNovoConfiguration } from '../query'

interface ColumnListActionProps {
    configuration: number
    refetch?: () => void
}

const ColumnListAction = (props: ColumnListActionProps) => {
    const { t } = useTranslation()
    const { configuration, refetch } = props
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const deleteNovoConfigurationMutation = useMutation(
        ['deleteNovoConfiguration'],
        () => deleteNovoConfiguration(String(configuration)),
        {
            onSuccess: () => {
                toast.success(`${t('message.delete_novo_configuration_success')}`)
                if (refetch) {
                    refetch()
                }
            },
            onError: (error: { message: string }) => {
                toast.error(error?.message || `${t('message.delete_novo_configuration_failed')}`)
            },
        },
    )

    return (
        <div className="flex items-center justify-center gap-2">
            <a
                href={`${NOVO_CONFIGURATION_ROUTE}/${configuration}`}
                title={t('api.view_novo_configuration_detail')}
            >
                <AiOutlineEye size={25} />
            </a>
            <a
                href={`${NOVO_CONFIGURATION_ROUTE}/history/${configuration}`}
                title={t('api.history_novo_configuration')}
            >
                <AiOutlineHistory size={20} />
            </a>
            <button
                type="button"
                onClick={() => {
                    setShowDeleteModal(true)
                }}
            >
                <AiFillDelete size={20} />
            </button>
            <AlertModal
                show={showDeleteModal}
                isSubmitting={deleteNovoConfigurationMutation.isLoading}
                onCancel={() => setShowDeleteModal(!showDeleteModal)}
                onConfirm={() => deleteNovoConfigurationMutation.mutate()}
                content={<p>{t('message.delete_novo_configuration_id')}</p>}
            />
        </div>
    )
}

export default ColumnListAction
